import React, { useLayoutEffect, useState, useEffect } from 'react';
import assets from '../../assets/images/Page1';
import './style.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import $ from 'jquery';

let imageCount = 0;

function Page1(args) {
  const [carouselWidth, setCarouselWidth] = useState(356);
  const [sliders, setSliders] = useState([]);
  const imageListForWaiting = ["n-right-homes", "o-homes", "o-way", "o-car", "sky"];

  const onImagesLoaded = (e) => {
    imageCount++;
    console.log(e.target.className);
    
    if(imageListForWaiting.length === imageCount){
      args.onImageLoad(true);
      updateSize();
    }
  };

  useEffect(() => {
    if(sliders.length === 0){
        $.ajax({
            url:'https://firatnft.com/admin/api.php?type=sliders',
            success: function(response){
                response = JSON.parse(response);
                if(response && response.success){
                  setSliders(response.sliders);
                }
            }
        });
    }
},[]);

  const updateSize = () => {
    if(window.innerWidth <= 300){
      setCarouselWidth(220);
    } else if(window.innerWidth <= 601){
      setCarouselWidth(227);
    } else if(window.innerHeight <= 690) {
      setCarouselWidth(246);
    }  else if(window.innerHeight <= 870 && window.innerWidth > 974) {
      setCarouselWidth(300);
    } else if(window.innerWidth < 975) {
      setCarouselWidth(245);
    } else {
      setCarouselWidth(372);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const buttonClick = () => {
    console.log('See The Collection');
  };

  const renderSlider = () => {
      if(sliders.length > 0){
        return (   
        <Carousel width={carouselWidth} autoPlay={true} transitionTime="3" infiniteLoop={true} showThumbs={false} showIndicators={false} showStatus={false} showArrows={false}>
            { sliders.map((sld, index)=>{
            let rand = Math.random();
            return(
              <div key={rand}>
                <img alt='' key={index} src={sld} />
              </div>
            );
          })}
        </Carousel>
      );
    } else {
      let rand = Math.random();
      return (
        <Carousel width={carouselWidth} autoPlay={true} transitionTime="3" infiniteLoop={true} showThumbs={false} showIndicators={false} showStatus={false} showArrows={false}>
          <div key={rand}>
            <img alt='' key={rand + 1} src={assets.slider1} />
          </div>
          <div key={rand + 2}>
              <img alt='' key={rand + 3} src={assets.firat} />
          </div>
        </Carousel>
      );
    }
  };

  return (
    <>
      <img alt='' onLoad={onImagesLoaded} className="sky" src={assets.sky}></img>
      <img alt='' className='p1plane' onClick={buttonClick} src={assets.p1plane}></img>
      <div className="n-way">
          <img alt='' onLoad={onImagesLoaded} className="o-homes" src={assets.o_homes}></img>
          <img alt='' onLoad={onImagesLoaded} className="o-way" src={assets.o_way}></img>
          <img alt='' onLoad={onImagesLoaded} className="o-car" src={assets.o_car}></img>
      </div>
      <div className="objects">
        <div className="left-objects">
          <div className="tablet-mobile-objects">
              <div className="right-area">
                <img alt='' className="grocery" src={assets.grocery}></img>
                <img alt='' className="kocagot" src={assets.kocagot}></img>
              </div>   
              <img alt='' className="ilgin" src={assets.ilgin}></img>
              <img alt='' className="building" src={assets.building}></img>
              <img alt='' className="basine" src={assets.basine}></img>
              <img alt='' className="tahsin" src={assets.tahsin}></img>
              <img alt='' className="baattin" src={assets.baattin}></img>
              <img alt='' className="firat" src={assets.firat}></img>
              <img alt='' className="dog" src={assets.dog}></img>
              <img alt='' className="terrainobjs" src={assets.terrainobjs}></img>
              <img alt='' className="basin" src={assets.basin}></img>
              <img alt='' className="firat" src={assets.firat}></img>           
          </div>
          <img alt='' className="scroll" src={assets.scroll}></img>
          <img alt='' className="n-firat" src={assets.n_firat}></img>
          {renderSlider()}
        </div>
        <img alt='' onLoad={onImagesLoaded} className="n-right-homes" src={assets.n_right_homes}></img>
        <img alt='' className="mobile-button" onClick={buttonClick} src={assets.mobilebutton}></img>

      </div>
    </>
  );
}

export default Page1;