import { useEffect, useState, useLayoutEffect } from 'react';
import Loader from './components/Loader';
import $ from 'jquery';
import './jquery.pagepiling';
import './jquery.pagepiling.css';
import Header from './components/Header';
import './App.css';
import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page2WhitePage from './components/Page2/Page2WhitePage';
import Page3 from './components/Page3';
import Page3WhitePage from './components/Page3/Page3WhitePage';
import Page4 from './components/Page4';
import Page4WhitePage from './components/Page4/Page4WhitePage';
import Page5 from './components/Page5';
import Announcements from './components/Announcements';
const isMobile = window.innerWidth <= 835 || window.innerHeight <= 750;

function App () {
  const [imagesLoaded, setimagesLoaded] = useState(false);
  const [texts, setTexts] = useState({});
  useEffect(()=>{
    $( document ).ready(function() {
      var headerColors = {
            p1: '#007ABF',
            p2: '#EAA008',
            ws2: '#EAA008',
            p3: '#EAA008',
            ws3: '#EAA008',
            p4: '#EAA008',
            ws4: '#EAA008',
            p5: '#EAA008',
            p6: '#EAA008'
      };

      var resetHeaderColor = function (){
        $('.nav-item:not(.connect)').css('color', '#FFFFFF');
        var activePage = $('section.active').attr('id') || 'p1';
        activePage =  activePage === 'ws2' ? 'p2' : activePage;
        activePage =  activePage === 'ws3' ? 'p3' : activePage;
        activePage =  activePage === 'ws4' ? 'p4' : activePage;
        $('[data-menuanchor="'+activePage+'"]').css('color', headerColors[activePage]);
        if(window.location.href.indexOf('ws') !== -1 || activePage.indexOf('p6') !== -1){
          $('a[data-menuanchor]:not([data-menuanchor="'+activePage+'"])').attr('style', 'color:#4B4B4B;');
        }
      };

      
      if ($('#pagepiling').length > 0){
        $('#pagepiling').pagepiling({
            scrollingSpeed: 100,
            navigation: false,
            anchors: ['p1', 'p6', 'p2', 'ws2', 'p3', 'ws3', 'p4', 'ws4', 'p5'],
            sectionSelector: 'section',
            normalScrollElements: isMobile ? '.carousel-item' : '.announce-text',
            afterRender: function(){
              resetHeaderColor();
            },
            afterLoad: function(anchorLink, index){
              resetHeaderColor();
              var activeId = parseInt(($('section.active').attr('data-order') || '1')) || 0;
              $('section').each(function(ind, element){
                if((ind + 1) < activeId && ($(element).attr('style') || '').indexOf('-100%') === -1){
                  $(element).css('transform', 'translate3d(0px, -100%, 0px)');
                }
                if((ind + 1) >= activeId && ($(element).attr('style') || '').indexOf('-100%') !== -1){
                  $(element).css('transform', 'translate3d(0px, 0px, 0px)');
                }
              });

            }
        });
      }
    });

    $.ajax({
      url:'https://firatnft.com/admin/api.php?type=texts',
      success: function(response){
          response = JSON.parse(response);
          if(response && response.success){
              setTexts(response.texts);
          }
      }
  });
  }, []);

  const updateSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const renderLoader = () => {
    updateSize();
    if(!imagesLoaded){
      return <Loader />;
    }
  };

    return (
      <div className="App">
        {renderLoader()}
        <Header mint={{
            id:'p1',
            text:'HOME'
          }}
          announcements={{
            id:'p6',
            text:'ANNOUNCEMENTS'
          }}
          history={{
            id:'p2',
            text:'HISTORY'
          }}
          collection={{
            id:'p3',
            text:'NFT'
          }}
          future={{
            id:'p4',
            text:'VISION'
          }}
          links={{
            id:'p5',
            text:'PLATFORMS',
            style: { "paddingRight": "0px" }
          }}/>
        <div id="pagepiling">
          <section className="section" id="p1" data-order="1">
            <div className="sectionContainer" style={{backgroundColor:'#ffffff'}}>
              <Page1 onImageLoad={setimagesLoaded}/>
            </div>
          </section>
          <section className="section" id="p6" data-order="2">
            <div className="sectionContainer wpcenter" style={{backgroundColor:'#ffffff'}}>
              <Announcements />
            </div>
          </section>
          <section className="section" id="p2" data-order="3">
            <div className="sectionContainer" style={{backgroundColor:'#ffffff'}}>
              <Page2 />
            </div>
          </section>
          <section className="section" id="ws2" data-order="4">
            <div className="sectionContainer wpcenter" style={{backgroundColor:'#ffffff'}}>
              <Page2WhitePage text={(texts.history || {})}/>
            </div>
          </section>
          <section className="section" id="p3" data-order="5">
            <div className="sectionContainer" style={{backgroundColor:'#ffffff'}}>
              <Page3 />
            </div>
          </section>
          <section className="section" id="ws3" data-order="6">
            <div className="sectionContainer wpcenter" style={{backgroundColor:'#ffffff'}}>
              <Page3WhitePage text={(texts.collection || {})}/>
            </div>
          </section>
          <section className="section" id="p4" data-order="7">
            <div className="sectionContainer" style={{backgroundColor:'#f38038'}}>
              <Page4 />
            </div>
          </section>
          <section className="section" id="ws4" data-order="8">
            <div className="sectionContainer wpcenter" style={{backgroundColor:'#ffffff'}}>
              <Page4WhitePage text={(texts.future || {})}/>
            </div>
          </section>
          <section className="section" id="p5" data-order="9">
            <div className="sectionContainer" style={{backgroundColor:'#000CFF'}}>
              <Page5 />
            </div>
          </section>
        </div>
      </div>
    );
}

export default App;