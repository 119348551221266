import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import assets from '../../assets/images/Page2WhitePage';

function Page2WhitePage({text}) {
  return (
    <>
        <div className="container text-center">
            <div className="row justify-content-center align-items-center wp2-m-container">
                <div className="col firat-wp2-text-container p-normal-scroll">
                    <h1 className="firat-wp2-title">{(text.title || {}) ? text.title : ("HISTORY OF FIRAT")}</h1>
                    <p className="firat-wp2-text">{(text.text || {}) ? text.text : ("Fırat stepped Into our word In <span className=\"wp2s-y\">2007</span> and created by the famous drawIng artIst <span className=\"wp2s-y\">Uğur Gürsoy</span>. He stole the hearts of many people In a very short tIme, receIved hIs own column wIthIn a magazIne,  and then contInued hIs amazIng work through publIshed books (reachIng mIllIons of people In real world and hundreds of thousands of socIal media followers).<br/><br/>Fırat has publIshed <span className=\"wp2s-y\">5 books</span> to date, and has been sold over <span className=\"wp2s-y\">200.000 copIes</span>. WhIle growIng the Image of FIrat, through very establIshed collaboratIons wIth famous brands, he never stopped makIng people laugh on Uykusuz magazIne for the last <span className=\"wp2s-y\">15 years</span>.")}</p>
                </div>
                <div className="col firat-wp2-img-container">
                    <img className="firat-wp2-img" src={assets.ws2firat}></img>
                </div>
            </div>
            {/* <span className="text-copyright">COPYRIGHT © 2022 FIRAT NFT</span> */}
        </div>
    </>
  );
}

export default Page2WhitePage;