import React, { useRef } from 'react';
import logo from '../../assets/images/logo.png';
import mmenu from '../../assets/images/mobile_menu.png';
import './style.css';

export default function Header(args) {
  const _renderMenuItems = Object.values(args).map((obj, i) => <a className="nav-item" style={obj.style} data-menuanchor={obj.id} href={'#'+obj.id} key={obj.id}>{obj.text}</a>);
  const navigation = useRef(null);
  const mobileMenuClick = () => {
    if(window.innerWidth <= 832){
      navigation.current.classList.toggle('active');
    }
  };
  return (
    <div id="header" className="header-container">
        <img alt='' className="mobile-menu" src={mmenu} onClick={mobileMenuClick}></img>
        <div className="logo-container">
            <img src={logo} className="app-logo" alt="logo" onClick={()=>{ window.location.href = '/'; }} />
        </div>
        <div className="navigation-container" onClick={mobileMenuClick} ref={navigation}>
          {_renderMenuItems}
        </div>
    </div>
  );
}
