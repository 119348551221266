import assets from '../../assets/images/Page4';
import './style.css';

function Page4() {
  return (
    <>
      <img alt='' className="p4terrain" src={assets.p4terrain}></img>
      <img alt='' className="p4wagmi" src={assets.p4wagmi}></img>
      <img alt='' className="p4mountains" src={assets.p4mountains}></img>
      <img alt='' className="p4firat" src={assets.p4firat}></img>
    </>
  );
}

export default Page4;