import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import assets from '../../assets/images/Page4WhitePage';

function Page4WhitePage({text}) {
  return (
    <>
        <div className="container text-center">
            <div className="row justify-content-center align-items-center wp4-m-container">
                <div className="col firat-wp4-text-container p-normal-scroll">
                    <h1 className="firat-wp4-title">{(text.title || {}) ? text.title : ("FUTURE OF FIRAT")}</h1>
                    <p className="firat-wp4-text">{(text.text || {}) ? text.text : ("Since the launch of his first collection, Firat has collaborated with big brands in real world & Web3, while creating joyful and valuable content for everyone! He aims to continue constant innovation and partnerships with only valuable projects to bring value to his collectors both in physical and digital world. Instead of creating a 'roadmap' or a 'to-do list' Firat focuses on innovating and creating everyday while counting on his community into every process. In addition to our existing collaborations Firat will announce new partnerships and projects to grow his fame and success.")}</p>
                </div>
                <div className="col firat-wp4-img-container">
                    <img className="firat-wp4-img" src={assets.ws4firat}></img>
                </div>
            </div>
            {/* <span className="text-copyright">COPYRIGHT © 2022 FIRAT NFT</span> */}
        </div>
    </>
  );
}

export default Page4WhitePage;