const assets = {
    p5baattin: require('./p5baattin.png'),
    p5discord: require('./p5discord.png'),
    p5duvar2: require('./p5duvar2.png'),
    p5firat: require('./p5firat.png'),
    p5instagram: require('./p5instagram.png'),
    p5kalao: require('./p5kalao.png'),
    p5kocagot: require('./p5kocagot.png'),
    p5perde: require('./p5perde.png'),
    p5traderjoe: require('./p5traderjoe.png'),
    p5twitter: require('./p5twitter.png'),
};

export default assets;