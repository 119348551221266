const assets = {
    baattin: require('./baattin.png'),
    backhomes: require('./backhomes.png'),
    basin: require('./basin.png'),
    basine: require('./basine.png'),
    building: require('./building.png'),
    button: require('./button.png'),
    mobilebutton: require('./mobile-button.png'),
    dog: require('./dog.png'),
    firat: require('./firat.png'),
    grocery: require('./grocery.png'),
    ilgin: require('./ilgin.png'),
    kocagot: require('./kocagot.png'),
    sky: require('./sky.png'),
    slider1: require('./slider1.png'),
    tahsin: require('./tahsin.png'),
    terrainobjs: require('./terrainobjs.png'),
    scroll: require('./scroll.png'),
    n_firat: require('./n_firat.png'),
    n_right_homes: require('./n_right_homes.png'),
    o_car: require('./o_car.png'),
    o_homes: require('./o_homes.png'),
    o_way: require('./o_way.png'),
    p1plane: require('./p1plane.png')        
};

export default assets;