import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import assets from '../../assets/images/Page3WhitePage';

function Page3WhitePage({text}) {
  return (
    <>
        <div className="container text-center">
            <div className="row justify-content-center align-items-center wp3-m-container">
                <div className="col firat-wp3-img-container">
                    <img className="firat-wp3-img" src={assets.ws3firat}></img>
                </div>
                <div className="col firat-wp3-text-container p-normal-scroll">
                    <h1 className="firat-wp3-title">{(text.title || {}) ? text.title : ("NFT OF FIRAT")}</h1>
                    <p className="firat-wp3-text">{(text.text || {}) ? text.text : ("As one of the most famous and popular <span className=\"wp3s-y\">cartoon characters</span>, bringing Firat into the NFT world as a colelction has been a hard, but fun and enlightening story. After a very selective decisions for the good of the community, Firat, and the universe he is currently creating, some creative processes were canceled after the Firat NFT Collection launch (December 25 2021). Additionally, the collection was sold out in a very short time and became one of the most successful projects within the Avalanche ecosystem. As still being the most loved and one of the highest traded (over 41k AVAX) collection, Firat has inspired many other projects to come alive and flourish.")}</p>
                </div>
            </div>
            {/* <span className="text-copyright">COPYRIGHT © 2022 FIRAT NFT</span> */}
        </div>
    </>
  );
}

export default Page3WhitePage;