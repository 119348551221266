import assets from '../../assets/images/Page5';
import './style.css';
import $ from 'jquery';
import { useEffect, useLayoutEffect, useState } from 'react';

function Page5() {
  let hoverState = 0;
  const clickRedirection = (e) => {
    const urls = ["https://twitter.com/firatinsayfasi",
    "https://www.instagram.com/firatinsayfasi/",
    "https://discord.gg/firat",
    "https://marketplace.kalao.io/collection/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789",
    "https://joepegs.com/collections/0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789"];
    window.open(urls[parseInt(e.target.id)], '_blank').focus();
  };

  useEffect(()=>{
    function startHoverInterval(state){
      hoverState = state;
      return setInterval(function(){
        let elementIndex = (hoverState % 5);
        if($('.p5socials img:eq('+(elementIndex -1)+')').length > 0){
          $('.p5socials img:eq('+(elementIndex -1)+')').removeClass('img-hover-active');  
        }
        $('.p5socials img:eq('+elementIndex+')').addClass('img-hover-active');
        hoverState++;
      },1000);
    }
    if(!window.hoverInterval){
      window.hoverInterval = startHoverInterval(0);
    }

    $('.p5socials img').off('mouseenter').on('mouseenter', function(){
      if(window.hoverInterval){
        clearInterval(window.hoverInterval);
        $('.p5socials img').removeClass('img-hover-active'); 
        window.hoverInterval = undefined;
      }
    });

    $('.p5socials img').off('mouseleave').on('mouseleave', function(){
      if(!window.hoverInterval){
        window.hoverInterval = startHoverInterval(0);
      }
    });
  },[]);

  return (
    <>
      <div className="p5terrain"></div>
      <img alt='' className="p5duvar2" src={assets.p5duvar2}></img>
      <img alt='' className="p5perde" src={assets.p5perde}></img>
      <div className="p5socials">
        <img alt='' id="0" className="p5twitter" src={assets.p5twitter} onClick={clickRedirection}></img>
        <img alt='' id="1" className="p5instagram" src={assets.p5instagram} onClick={clickRedirection}></img>
        <img alt='' id="2" className="p5discord" src={assets.p5discord} onClick={clickRedirection}></img>
        <img alt='' id="3" className="p5kalao" src={assets.p5kalao} onClick={clickRedirection}></img>
        <img alt='' id="4" className="p5traderjoe" src={assets.p5traderjoe} onClick={clickRedirection}></img>
      </div>
      <img alt='' className="p5baattin" src={assets.p5baattin}></img>
      <img alt='' className="p5firat" src={assets.p5firat}></img>
      <img alt='' className="p5kocagot" src={assets.p5kocagot}></img>
    </>
  );
}

export default Page5;