import assets from '../../assets/images/Page2';
import './style.css';

function Page2() {
  return (
    <>
       <div className="wall"></div>
       <img alt='' className="p2supurgelik" src={assets.p2supurgelik}></img>
       <img alt='' className="p2knight" src={assets.p2knight}></img>
       <img alt='' className="fireplace" src={assets.fireplace}></img>
       <img alt='' className="p2firat" src={assets.firat}></img>
       <img alt='' className="dragon" src={assets.dragon}></img>
       <div className="p2terrain"></div>
    </>
  );
}

export default Page2;