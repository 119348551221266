import assets from '../../assets/images/Page3';
import './style.css';


function Page3() {
  return (
    <>
       <div className="p3terrain"></div>
       <img alt='' className="p3wall" src={assets.p3wall}></img>
       <img alt='' className="p3closet" src={assets.p3closet}></img>
       <img alt='' className="p3poster" src={assets.p3poster}></img>
       <img alt='' className="p3postertablet" src={assets.p3postertablet}></img>
       <img alt='' className="p3firat" src={assets.p3firat}></img>
       <img alt='' className="p3clothes" src={assets.p3clothes}></img>
       <img alt='' className="p3wallterrain" src={assets.p3terrain}></img>
    </>
  );
}

export default Page3;