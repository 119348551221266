import { useEffect, useState } from 'react';
import './style.css';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel'

function Announcements() {
    const [announcements, setAnnouncements] = useState([]);

    const handleScrollY = () => {
        if($('.carousel-item').length > 0){
            var scrollDiff = (window.innerHeight  / 12) > 90 ? 90 : (window.innerHeight  / 10);
            var ts = 0;
            $('.carousel-item').bind('touchstart', function (e){
               ts = e.originalEvent.touches[0].clientY;
            });
    
            $('.carousel-item').bind('touchend', function (e){
               var te = e.originalEvent.changedTouches[0].clientY;
               if(e.target.className !== 'announce-text'){
                if(ts > te+scrollDiff){
                    window.location.href = window.location.href.replace('#p6', '#p2');
                   }else if(ts < te-scrollDiff){
                    window.location.href = window.location.href.replace('#p6', '#p1');
                   }
               }
            });
        } else {
            setTimeout(function(){
                handleScrollY();
            }, 1000);
        }
    };

    useEffect(() => {
        if(announcements.length === 0){
            $.ajax({
                url:'https://firatnft.com/admin/api.php?type=announcements',
                success: function(response){
                    response = JSON.parse(response);
                    if(response && response.success){
                        setAnnouncements(response.announcements);
                        handleScrollY();
                    }
                }
            });
        }
    },[]);

    const Announce = ({item}) => {
        return (
            <div className="announce-wrapper">
                <img className="announce-image" src={item.imgurl}></img>
                <div className="announce-text-container">
                    <h1 className="announce-title">{item.title}</h1>
                    <p className="announce-text">{item.text}</p>
                    <p className="announce-date">{item.ts_start}</p>
                </div>
            </div>
        );
    };

    const renderAnnounces = () => {
         if(announcements.length > 0){
            return announcements.map((ann, index)=>{
                let rand = Math.random();
                return( <Carousel.Item key={index}>
                            <Announce key={rand} item={ann}/>
                        </Carousel.Item>);
            });
         }
    };
  return (
    <>
        <div className="announce-container">
            <Carousel indicators={true} slide={false} interval={null}>
                {renderAnnounces()}
            </Carousel>
        </div>
    </>
  );
}

export default Announcements;