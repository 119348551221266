import { useEffect } from 'react';
import './style.css';
import assets from '../../assets/images/index.js';

function Loader() {
    // useEffect(() => {
    //     let dotCount = 0;
    //     const endOfText = ['.', '..', '...'];
    //     let intervalId = setInterval(function(){
    //         document.getElementsByClassName('loading-text')[0].innerText = "Loading" + endOfText[dotCount++ % 3];
    //     },1000)
    //     return(() => {
    //         clearInterval(intervalId)
    //     })
    // },[]);

  return (
    <>
        <div className="loading-wrapper">
            <img className="loading-img" src={assets.loading}></img>
            <div className="loading-gif"></div>
        </div>
    </>
  );
}

export default Loader;