const assets = {
    p3closet: require('./p3closet.png'),
    p3firat: require('./p3firat.png'),
    p3wallterrain: require('./p3wallterrain.png'),
    p3clothes: require('./p3clothes.png'),
    p3terrain: require('./p3terrain.png'),
    p3wall: require('./p3wall.png'),
    p3poster: require('./p3poster.png'),
    p3postertablet: require('./p3postertablet.png')
};

export default assets;